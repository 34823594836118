import { Link } from "@/components/catalyst/link";
import { FeatureFlagType, InvoiceStatus } from "@/gql/graphql";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";

export function OverdueInvoicesWarning() {
  const { data, isLoading } = useGqlQuery(metaQuery, {});
  const isEnabled = useFeatureFlag(FeatureFlagType.InvoiceOverdueBanner);

  if (isLoading || data?.meta.overdue_invoice_count === 0 || !isEnabled) {
    return null;
  }

  return (
    <div className="flex items-center justify-center gap-x-6 bg-red-600 px-6 py-2.5 sm:px-3.5">
      <p className="text-sm leading-6 text-white">
        <Link
          to={"/invoices"}
          search={{
            invoiceStatus: [InvoiceStatus.Issued, InvoiceStatus.Overdue],
          }}
        >
          <strong className="font-semibold">
            You have {data?.meta.overdue_invoice_count} overdue invoices
          </strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          View invoices&nbsp;
          <span aria-hidden="true">&rarr;</span>
        </Link>
      </p>
    </div>
  );
}
