import { ReportBookingsTable } from "@/app/Reports/Components/ReportBookings/ReportBookingsTable";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { BookingListItem } from "@/app/Bookings/GraphQL/bookingListQuery";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { Suspense, useCallback, useRef } from "react";
import { Heading } from "@/components/catalyst/heading";
import { Button } from "@/components/catalyst/button";
import { DateRangePicker } from "@/components/ui/date-range/date-range-picker";
import dayjs from "dayjs";
import { MultiSelect, MultiSelectOption } from "@/components/ui/multi-select";
import { AgGridReact } from "ag-grid-react";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierListQuery } from "@/app/Suppliers/GraphQL/supplierListQuery";
import { QuerySuppliersOrderByColumn, SortOrder } from "@/gql/graphql";
import { Skeleton } from "@/components/ui/skeleton";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function ReportBookingScreen() {
  const gridRef = useRef<AgGridReact<BookingListItem>>(null);
  const { hasSupplier } = useGlobalSupplier();

  const { startDate, endDate } = useSearch({
    from: "/_app/reports/bookings",
  });

  const navigate = useNavigate({
    from: "/reports/bookings",
  });

  const onBtExport = useCallback((type: "csv" | "xls") => {
    const onlySelected = gridRef.current?.api.getSelectedRows().length !== 0;

    if (type === "csv") {
      gridRef.current!.api.exportDataAsCsv({
        onlySelected,
      });
    } else {
      gridRef.current!.api.exportDataAsExcel({
        onlySelected,
      });
    }
  }, []);

  return (
    <FullPageScreen containerClassName="bg-gray-50 pb-0">
      <div className="flex h-full flex-col space-y-3">
        <div className="p-3P flex w-full flex-wrap items-end justify-between gap-4 rounded-lg border-b border-zinc-950/10 bg-white p-3">
          <Heading>Bookings</Heading>
          <div className="flex gap-4">
            <Button outline onClick={() => onBtExport("csv")}>
              Export csv
            </Button>
            <Button onClick={() => onBtExport("xls")}>Export excel</Button>
          </div>
        </div>

        <div className="flex-grow rounded-lg bg-white p-3">
          <div className="flex gap-2">
            <DateRangePicker
              align="start"
              initialDateFrom={dayjs(startDate).toDate()}
              initialDateTo={dayjs(endDate).toDate()}
              onUpdate={({ range }) => {
                navigate({
                  search: (old) => {
                    return {
                      ...old,
                      startDate: dayjs(range.from).format("YYYY-MM-DD"),
                      endDate: dayjs(range.to).format("YYYY-MM-DD"),
                    };
                  },
                });
              }}
            />
            {!hasSupplier ? <SupplierMultiSelect /> : null}
          </div>

          <Suspense fallback={<Skeleton className="mt-3 h-80" />}>
            <ReportBookingsTable ref={gridRef} />
          </Suspense>
        </div>
      </div>
    </FullPageScreen>
  );
}

function SupplierMultiSelect() {
  const navigate = useNavigate({
    from: "/reports/bookings",
  });
  const { supplierIds } = useSearch({
    from: "/_app/reports/bookings",
  });
  const { data: suppliers } = useSuspenseGqlQuery(supplierListQuery, {
    first: 200,
    page: 1,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QuerySuppliersOrderByColumn.Name,
      },
    ],
  });

  return (
    <MultiSelect
      className="w-auto"
      placeholder="Select supplier"
      options={suppliers.suppliers.data.map((supplier) => {
        return {
          label: supplier.name,
          value: supplier.id,
        } satisfies MultiSelectOption;
      })}
      defaultValue={supplierIds}
      onValueChange={(value) => {
        navigate({
          search: (old) => {
            return {
              ...old,
              supplierIds: value,
            };
          },
        });
      }}
    />
  );
}
