import { HTMLAttributes, ReactNode } from "react";
import { cn } from "@/lib/utils";

type FullPageScreenProps = {
  children: ReactNode;
  hero?: ReactNode;
  containerClassName?: string;
} & HTMLAttributes<HTMLDivElement>;

export function FullPageScreen({
  children,
  hero,
  className,
  containerClassName,
  ...props
}: FullPageScreenProps) {
  return (
    <div className={cn("h-full lg:overflow-y-scroll", containerClassName)}>
      {hero}
      <div
        className={cn("mx-auto h-full max-w-7xl px-6 lg:py-12", className)}
        {...props}
      >
        {children}

        {/** Ensure we can always scroll past **/}
        <div className="h-60" />
      </div>
    </div>
  );
}
