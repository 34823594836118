import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationHighlightCreateDialog } from "@/app/Tools/RelocationHighlights/Components/RelocationHighlightCreateDialog";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { QueryRelocationHighlightsOrderColumn, SortOrder } from "@/gql/graphql";
import {
  RelocationHighlightListItem,
  relocationHighlightListQuery,
} from "@/app/Tools/RelocationHighlights/GraphQL/relocationHighlightListQuery";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useRelocationHighlightActions } from "@/app/Tools/RelocationHighlights/Hooks/useRelocationHighlightActions";

type RelocationHighlightColumnId =
  | "region"
  | "text"
  | "order"
  | "departure_city"
  | "delivery_city"
  | "created_at"
  | "updated_at"
  | "actions";

export function RelocationHighlightTable() {
  const { open } = useDialog(RelocationHighlightCreateDialog);
  const getActions = useRelocationHighlightActions();
  const columns: DataTableColDef<
    RelocationHighlightListItem,
    unknown,
    RelocationHighlightColumnId
  >[] = [
    {
      id: "text",
      header: "Text",
      accessorFn: (row) => `${row.primary_text} ${row.secondary_text}`,
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (row) => row.region,
    },
    {
      id: "departure_city",
      header: "Departure city",
      cell: ({ row }) => (
        <ModelLinkCell
          to="/cities/$id"
          params={{
            id: row.original.deliveryCity!.id,
          }}
        >
          {row.original.departureCity?.name ?? "--"}
        </ModelLinkCell>
      ),
    },
    {
      id: "delivery_city",
      header: "Delivery city",
      accessorFn: (row) => row.deliveryCity,
      cell: ({ row }) => (
        <ModelLinkCell
          to="/cities/$id"
          params={{
            id: row.original.deliveryCity!.id,
          }}
        >
          {row.original.deliveryCity?.name ?? "--"}
        </ModelLinkCell>
      ),
    },
    {
      id: "order",
      header: "Position",
      accessorFn: (row) => row.order,
    },
  ];

  return (
    <DataTable<
      ResultOf<typeof relocationHighlightListQuery>,
      VariablesOf<typeof relocationHighlightListQuery>,
      RelocationHighlightColumnId,
      RelocationHighlightListItem,
      unknown
    >
      id="relocation-highlights"
      accessor={(data) => data.relocationHighlights}
      document={relocationHighlightListQuery}
      columns={columns}
      getActions={getActions}
      title="Relocation highlights"
      rightButtons={
        <Button
          LeadingIcon={PlusIcon}
          onClick={() => {
            open({});
          }}
          className="btn btn-primary"
        >
          Add highlight
        </Button>
      }
      getQueryVariables={({ pagination }) => {
        return {
          page: pagination.pageIndex,
          first: pagination.pageSize,

          order: [
            {
              column: QueryRelocationHighlightsOrderColumn.CreatedAt,
              order: SortOrder.Asc,
            },
          ],
        };
      }}
    />
  );
}
