import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import {
  CityListItem,
  cityListQuery,
} from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { cityRecordQuery } from "@/app/Cities/Cities/GraphQL/cityRecordQuery";
import { useTranslations } from "use-intl";
import { Region } from "@/gql/graphql";

const showState: {
  [key in Region]: boolean;
} = {
  [Region.Au]: true,
  [Region.Ca]: true,
  [Region.Eu]: true,
  [Region.Jp]: false,
  [Region.Nz]: false,
  [Region.Pi]: true,
  [Region.Sacu]: true,
  [Region.Us]: true,
};
export function CityInput({
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof cityListQuery>,
  VariablesOf<typeof cityListQuery>,
  CityListItem,
  ResultOf<typeof cityRecordQuery>,
  VariablesOf<typeof cityRecordQuery>
>) {
  const t = useTranslations("city.state");

  return (
    <ComboboxInput
      document={cityListQuery}
      accessor={(data) => data.cities.data}
      getQueryVariables={(search) => ({
        search,
        first: 20,
        page: 1,
      })}
      recordAccessor={(data) => data.city}
      recordDocument={cityRecordQuery}
      getViewNode={(item) =>
        item
          ? showState[item.region]
            ? `${item.name} (${t(item.state)})`
            : item.state
          : "--"
      }
      getKey={(item) => item.id}
      getInputNode={(item) =>
        showState[item.region] ? `${item.name} (${t(item.state)})` : item.state
      }
      getListItemNode={(item) => (
        <div>
          <p>{item.name}</p>
          <p className="text-xs text-gray-500">
            {item.region}
            {showState[item.region] ? ` - ${t(item.state)}` : null}
          </p>
        </div>
      )}
      {...props}
    />
  );
}
