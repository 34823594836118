import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  QuoteListItem,
  quoteListQuery,
} from "@/app/Quotes/GraphQL/quoteListQuery";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { ReactNode } from "react";
import { Badge } from "@/components/catalyst/badge";
import { Link } from "@/components/catalyst/link";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { formatDate } from "@/lib/Formatters/formatDate";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useQuoteActions } from "@/app/Quotes/hooks/useQuoteActions";

export function QuoteTable({ buttons }: { buttons?: ReactNode }) {
  const getActions = useQuoteActions();
  const columns: DataTableColDef<QuoteListItem, any, any>[] = [
    {
      id: "quote",
      accessorFn: (data) => data.reference,
      header: "Quote",
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/quotes/$quoteId"}
          params={{
            quoteId: row.original.id,
          }}
        >
          {row.original.reference}
        </ModelLinkCell>
      ),
    },
    {
      id: "status",
      header: "Status",
      accessorFn: (row) => row.status,
      cell: ({ row }) => {
        return <Badge>{row.original.status}</Badge>;
      },
    },
    {
      id: "payee",
      header: "Payee",
      cell: ({ row }) => (
        <div>
          <p>
            <Link
              className="text-blue-500 underline"
              to={"/contacts/$id"}
              params={{
                id: row.original.payee.id,
              }}
              search={{}}
            >
              {row.original.payee.name}
            </Link>
          </p>
        </div>
      ),
    },
    {
      id: "amount",
      header: "Amount",
      accessorFn: (row) => formatCurrency(row.amount_inc_tax, row.currency),
      cell: DeEmphasisedCell,
    },
    {
      id: "due_date",
      header: "Due Date",
      accessorFn: (row) => formatDate(row.expiry_date),
      cell: DeEmphasisedCell,
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
      cell: DeEmphasisedCell,
    },
  ];

  return (
    <DataTable
      columns={columns}
      document={quoteListQuery}
      getQueryVariables={({ pagination, sorting, search }) => {
        return {
          search,
          first: pagination.pageSize,
          page: pagination.pageIndex,
        };
      }}
      accessor={(data) => data.quotes}
      id={"quotes"}
      rightButtons={buttons}
      getActions={getActions}
    />
  );
}
