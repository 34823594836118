import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { Link } from "@/components/catalyst/link";
import {
  AllUserListItem,
  allUserListQuery,
} from "@/app/Users/GraphQL/allUserListQuery";
import { userRecordQuery } from "@/app/Users/GraphQL/userRecordQuery";

export function AllUserInput({
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof allUserListQuery>,
  VariablesOf<typeof allUserListQuery>,
  AllUserListItem,
  any
>) {
  return (
    <ComboboxInput
      document={allUserListQuery}
      accessor={(data) => data.allUsers.data}
      getQueryVariables={(search) => ({
        first: 20,
        page: 1,
        search,
      })}
      recordDocument={userRecordQuery}
      recordAccessor={(data) => data.user!}
      getViewNode={(item) =>
        item ? (
          <Link
            className="text-blue-500"
            to="/users/$id"
            params={{
              id: item.id,
            }}
          >
            {item.name}
          </Link>
        ) : null
      }
      getKey={(i) => i.id}
      getListItemNode={(i) => (
        <div>
          <p>{i.name}</p>
          <p className="text-xs text-gray-500">{i.primary_email}</p>
        </div>
      )}
      getInputNode={(i) => i.name}
      {...props}
    />
  );
}
