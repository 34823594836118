import { LinkComponent } from "@tanstack/react-router";
import { BaseLinkComponent, Link } from "@/components/catalyst/link";
import { cn } from "@/lib/utils";

export const ModelLinkCell: LinkComponent<typeof BaseLinkComponent> = ({
  className,
  ...props
}) => {
  return (
    <Link
      preload={"intent"}
      {...props}
      className={cn("text-blue-500 underline", className)}
    />
  );
};
