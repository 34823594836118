import { ReactNode } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { cn } from "@/lib/utils";

interface WarningBannerProps {
  children: ReactNode;
  className?: string;
}
export function WarningBanner({ children, className }: WarningBannerProps) {
  return (
    <div
      className={cn("border-l-4 border-yellow-400 bg-yellow-50 p-4", className)}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <div className="text-sm text-yellow-700">{children}</div>
        </div>
      </div>
    </div>
  );
}
