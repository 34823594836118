import { CellContext } from "@tanstack/react-table";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";
import { cn } from "@/lib/utils";
import { Link } from "@/components/catalyst/link";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { markRelocationLineReferenceAsSoldMutation } from "@/app/Relocations/GraphQL/relocationLineReferenceMutations";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { LockIcon, UnlockIcon } from "lucide-react";

export function RelocationReferenceCell({
  row,
}: CellContext<RelocationListItem, any>) {
  const line = row.original.line;

  if (line.reference === null) {
    return "--";
  }

  return (
    <ul>
      {line.references.map((ref) => {
        let color = "bg-green-500";

        if (ref.sold_at) {
          if (ref.booking) {
            color = "bg-yellow-500";
          } else {
            color = "bg-red-500";
          }
        }

        return (
          <li key={ref.id} className="flex items-center space-x-1">
            <div className={cn(color, "inline-flex h-2 w-2 rounded-full")} />
            <div className="w-[100px] truncate" title={ref.reference}>
              {ref.reference}
            </div>
            <div className="flex-shrink-0">
              {ref.booking ? (
                <Link
                  className="text-blue-500 underline"
                  to={"/bookings/$bookingId"}
                  params={{
                    bookingId: ref.booking?.id,
                  }}
                >
                  {ref.booking?.reference ?? "--"}
                </Link>
              ) : null}

              <ReserveButton reference={ref} />
            </div>
          </li>
        );
      })}
    </ul>
  );
}

function ReserveButton({ reference }: { reference: any }) {
  const { isAdmin } = useTenant();
  const { mutateAsync } = useGqlMutation(
    markRelocationLineReferenceAsSoldMutation,
    {
      onSuccess: () => {
        return QueryClient.invalidateQueries();
      },
    },
  );

  if (!isAdmin) return null;

  if (reference.sold_at) {
    return (
      <TextButton
        className="text-red-500"
        Icon={UnlockIcon}
        onClick={async () => {
          await mutateAsync({
            id: reference.id,
            sold: false,
          });
        }}
      >
        free
      </TextButton>
    );
  }

  return (
    <TextButton
      Icon={LockIcon}
      onClick={async () => {
        await mutateAsync({
          id: reference.id,
          sold: true,
        });
      }}
    >
      sold
    </TextButton>
  );
}
