import { createLink } from "@tanstack/react-router";
import * as React from "react";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import * as Headless from "@headlessui/react";

type BasicLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function BaseLinkComponent(props: BasicLinkProps) {
  const { data } = useSuspenseGqlQuery(metaQuery, {});

  const shouldReload =
    data.meta.build_sha !== import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;

  return (
    <Headless.DataInteractive>
      <a
        {...props}
        onClick={(e) => {
          if (shouldReload && !import.meta.env.DEV) {
            e.preventDefault();
            window.location.href = e.currentTarget.href;
          }

          return props.onClick?.(e);
        }}
      />
    </Headless.DataInteractive>
  );
}

export const Link = createLink(BaseLinkComponent);
