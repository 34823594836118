import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type ReportBookingListItem = ResultOf<
  typeof reportBookingListQuery
>["bookings"]["data"][number];

export const reportBookingListQuery = graphql(`
  query ReportBookingList(
    $first: Int!
    $page: Int
    $orderBy: [QueryBookingsOrderByRelationOrderByClause!]
    $status: [BookingStatus!]
    $whereRelocation: QueryBookingsWhereRelocationWhereHasConditions
    $where: QueryBookingsWhereWhereConditions
  ) {
    bookings(
      page: $page
      first: $first
      orderBy: $orderBy
      status: $status
      where: $where
      whereRelocation: $whereRelocation
    ) {
      data {
        id
        reference
        currency
        name
        email
        phone
        status
        currency
        date_of_birth
        created_at
        depart_at
        deliver_at
        supplier_reference
        customer_payment_amount

        total_booking_value
        pay_now_full_price_units_deposit_amount

        relocation {
          id
          hire_unit_type
          measurement
          reference

          booking_fee_amount
          holding_deposit_amount
          charge_per_move_amount

          supplier {
            id
            name
          }
          vehicle {
            id
            name
          }

          departure_city_id
          delivery_city_id
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
