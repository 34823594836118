import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type RelocationRecord = ResultOf<
  typeof relocationRecordQuery
>["relocation"];
export const relocationRecordQuery = graphql(`
  query RelocationRecord($id: ID!) {
    relocation(id: $id) {
      ...RelocationFields
      bookedBookingsCount
    }
  }
`);

export const relocationFieldsFragment = graphql(`
  fragment RelocationFields on Relocation {
    id
    created_at
    updated_at
    reference

    is_linked
    linkedRelocationCount

    available_from_date
    available_to_date
    fixed_latest_departure_date

    departure_dates {
      date
      ranges {
        start
        end
      }
    }

    status
    count

    measurement
    currency

    hire_unit_rate
    hire_unit_type
    hire_units_allowed
    minimum_hire_units
    extra_hire_unit_rate
    extra_hire_units_allowed
    extra_hire_unit_deposit_rate
    extra_hire_unit_supplier_net_rate
    holding_deposit_amount
    holding_deposit_refunded_at
    holding_deposit_refunded_by
    charge_per_move_amount
    booking_fee_amount
    distance_allowed
    excess_distance_rate
    extra_distance_allowed_per_extra_hire_unit
    seatbelts
    is_ferry_required

    createdBy {
      ...UserFields
    }

    supplier {
      ...SupplierFields
    }
    supplierInsuranceOption {
      ...SupplierInsuranceOptionRecordFields
    }

    vehicle {
      id
      name
      code
      hire_unit_type
      hire_unit_rate
      transmission
      child_seat_anchor_points
      type
      brand
      model
      approximate_age
      sleeps
      seatbelts
      type
      fuel
      has_heating
      has_kitchen
      has_shower_and_toilet
      is_self_contained
      is_pet_friendly
      pet_cleaning_fee

      heroImage
      created_at
      updated_at
      images {
        ...MediaFields
      }
    }
    departureOffice {
      id
      name
      address {
        ...AddressFields
      }
    }
    deliveryOffice {
      id
      name
      address {
        ...AddressFields
      }
    }
    deliveryCity {
      id
      name
      region
      state
    }
    departureCity {
      id
      name
      region
      state
    }
    inclusions {
      ...RelocationInclusionFields
    }

    line {
      id
      reference
      expires_at
      references {
        id
        sold_at
        reference
        booking {
          id
          reference
        }
      }
    }

    stats {
      views_today
      views_yesterday
      views_total
      days_until_expiry
      bookings_count
      pending_bookings_count
      confirmed_bookings_count
      completed_bookings_count
      rating {
        ...RelocationRatingFields
      }
    }
  }
`);
