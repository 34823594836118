import { Link } from "@/components/catalyst/link";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { formatDate } from "@/lib/Formatters/formatDate";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";

export function BookingDuplicateWarning({
  booking,
}: {
  booking: BookingRecord;
}) {
  if (booking.potentialDuplicates.length === 0) {
    return null;
  }

  return (
    <WarningBanner className="rounded-md shadow-sm">
      <p>
        This booking is a potential duplicate. Please verify the details before
        proceeding.
      </p>
      <ul>
        {booking.potentialDuplicates.map((duplicate) => {
          return (
            <li key={duplicate.id}>
              <Link
                to={"/bookings/$bookingId"}
                params={{
                  bookingId: duplicate.id,
                }}
                search={(s: any) => s}
                className="mr-2 text-blue-500 underline hover:text-blue-700"
              >
                {duplicate.reference}
              </Link>

              <span>
                {`${duplicate.relocation.departureCity.name} to 
                ${duplicate.relocation.deliveryCity.name} - 
                ${formatDate(duplicate.depart_at)} to
                ${formatDate(duplicate.deliver_at)}`}
              </span>
            </li>
          );
        })}
      </ul>
    </WarningBanner>
  );
}
