import { FC, ReactNode } from "react";
import { BellAlertIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import {
  CalendarCheckIcon,
  ClipboardCheckIcon,
  ClipboardXIcon,
  TimerOffIcon,
} from "lucide-react";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import {
  FeatureFlagType,
  SupplierNotificationSettingEvent,
} from "@/gql/graphql";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierNotificationSettingDialog } from "@/app/Suppliers/Components/SupplierNotificationSettingDialog";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";
import { Badge } from "@/components/catalyst/badge";

export function SupplierNotificationSettingEventButtons({
  supplier,
  title = "Notifications",
  className,
}: {
  supplier: SupplierRecord;
  className?: string;
  title?: ReactNode;
}) {
  const acceptedEnabled = useFeatureFlag(FeatureFlagType.BookingAcceptedState);

  const buttons = [
    {
      label: "Booking pending",
      Icon: CalendarDaysIcon,
      event: SupplierNotificationSettingEvent.BookingPending,
    },
    {
      label: "Booking confirmed",
      Icon: CalendarCheckIcon,
      event: SupplierNotificationSettingEvent.BookingConfirmed,
    },
    {
      label: "Booking cancelled",
      Icon: ClipboardXIcon,
      event: SupplierNotificationSettingEvent.BookingCancelledAfterConfirmation,
    },
    {
      label: "Pending booking cancelled",
      Icon: ClipboardXIcon,
      event:
        SupplierNotificationSettingEvent.BookingCancelledBeforeConfirmation,
    },
    {
      label: "Relocation expired",
      Icon: TimerOffIcon,
      event: SupplierNotificationSettingEvent.RelocationsExpired,
    },
    {
      label: "Pending Booking Reminder",
      Icon: BellAlertIcon,
      event: SupplierNotificationSettingEvent.BookingPendingReminder,
    },
    ...(!acceptedEnabled
      ? [
          {
            label: "Booking accepted",
            Icon: ClipboardCheckIcon,
            event: SupplierNotificationSettingEvent.BookingAccepted,
          },
        ]
      : []),
  ];

  return (
    <RecordScreenCard title={title} className={className}>
      <div className="grid grid-cols-4 gap-6">
        {buttons.map((button, idx) => (
          <EventButton key={idx} supplier={supplier} {...button} />
        ))}
      </div>
    </RecordScreenCard>
  );
}

type EventButtonProps = {
  label: ReactNode;
  Icon: FC<any>;
  event: SupplierNotificationSettingEvent;
  supplier: SupplierRecord;
};
function EventButton({ label, Icon, event, supplier }: EventButtonProps) {
  const { open } = useDialog(SupplierNotificationSettingDialog);

  const count =
    supplier.notificationSettings?.filter((setting) => setting.event === event)
      .length ?? 0;

  return (
    <button
      className="flex aspect-square items-center justify-center rounded-md border border-gray-200 bg-white px-4 py-4 text-gray-700 shadow-lg transition-colors hover:bg-gray-100"
      onClick={() => {
        open({
          supplier,
          event,
        });
      }}
    >
      <div className="flex flex-col items-center space-y-3">
        <Icon className="h-10 w-10" />
        <p className="text-xs text-gray-500">{label}</p>
        <p>
          <Badge color={count ? "blue" : "zinc"}>{`${count} recipients`}</Badge>
        </p>
      </div>
    </button>
  );
}
