import { CellContext } from "@tanstack/react-table";
import {
  BookingListQueryVariables,
  BookingStatus,
  QueryBookingsOrderByColumn,
  SortOrder,
} from "@/gql/graphql";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useState } from "react";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingFindRelocationDialog } from "@/app/Bookings/Components/BookingFindRelocationDialog";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { formatDateTime } from "@/lib/Formatters/formatDateTime";
import { useTranslations } from "use-intl";
import { Link } from "@/components/catalyst/link";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  BookingListItem,
  bookingListQuery,
} from "@/app/Bookings/GraphQL/bookingListQuery";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { Badge } from "@/components/catalyst/badge";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";
import { inclusionIconMap } from "@/app/Relocations/Utils/inclusionIconMap";
import {
  MultiSelectFilter,
  MultiSelectFilterOption,
} from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { TableId } from "@/app/Common/Utils/tableIds";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import { RelocationDetailsDialog } from "@/app/Relocations/Components/RelocationDetailsDialog";
import { useBookingActions } from "@/app/Bookings/Hooks/useBookingActions";
import { OfficeFilter } from "@/lib/Components/DataTable/Filters/OfficeFilter";
import { VehicleFilter } from "@/lib/Components/DataTable/Filters/VehicleFilter";
import { CopyToClipboard } from "@/lib/Components/Common/CopyToClipboard";
import { Highlighter } from "@/lib/Components/Highlighter";

type BookingStatusType =
  | "pending"
  | "vip"
  | "confirmed"
  | "completed"
  | "cancelled"
  | "incomplete";

const options: MultiSelectFilterOption<BookingStatusType>[] = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "VIP",
    value: "vip",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Abandoned checkout",
    value: "incomplete",
  },
];

const statusTypeToStatusMap: Record<BookingStatusType, BookingStatus[]> = {
  pending: [BookingStatus.AwaitingConfirmation, BookingStatus.Accepted],
  vip: [BookingStatus.Vip],
  confirmed: [BookingStatus.Confirmed],
  completed: [BookingStatus.Completed],
  cancelled: [
    BookingStatus.AdminCancelled,
    BookingStatus.SupplierCancelledNotAvailable,
    BookingStatus.SupplierCancelledNoFerry,
    BookingStatus.SupplierCancelledMechanicalFailure,
    BookingStatus.SupplierCancelled,
    BookingStatus.CustomerCancelled,
    BookingStatus.CustomerCancelledNoShow,
    BookingStatus.CustomerCancelledRebooked,
    BookingStatus.CustomerCancelledConfirmationTimeliness,
  ],
  incomplete: [BookingStatus.AwaitingPayment],
};

function getStatusTypes(statuses: BookingStatus[]): BookingStatusType[] {
  const foundTypes: BookingStatusType[] = [];
  for (const [key, value] of Object.entries(statusTypeToStatusMap)) {
    //Test that all statuses are present
    const areAllStatusesPresent = value.every((status) =>
      statuses.includes(status),
    );
    if (areAllStatusesPresent) {
      foundTypes.push(key as BookingStatusType);
    }
  }

  return foundTypes;
}

type BookingTableProps = {
  queryVariables?: Partial<BookingListQueryVariables>;
  title?: string;
  hiddenColumns?: BookingColumnId[];
  id: TableId;

  //Un-controlled component
  defaultEnabledStatuses?: BookingStatus[];

  //Controlled component
  statuses?: BookingStatus[];
  onStatusChange?: (status: BookingStatus[]) => void;
};

type BookingColumnId =
  | "booking"
  | "status"
  | "matches"
  | "relocation"
  | "dates"
  | "customer"
  | "pax"
  | "trip"
  | "actions"
  | "created_at";

const sortMap: { [key in BookingColumnId]?: QueryBookingsOrderByColumn } = {
  status: QueryBookingsOrderByColumn.Status,
  created_at: QueryBookingsOrderByColumn.CreatedAt,
  booking: QueryBookingsOrderByColumn.Reference,
  dates: QueryBookingsOrderByColumn.DepartAt,
  customer: QueryBookingsOrderByColumn.Name,
};

export function BookingTable({
  queryVariables,
  title,
  id,
  hiddenColumns,

  //Controlled
  statuses,
  onStatusChange,

  //Un-controlled
  defaultEnabledStatuses,
}: BookingTableProps) {
  const { supplier } = useGlobalSupplier();
  const getActions = useBookingActions();
  const [departFrom, setDepartFrom] = useState<string[]>([]);
  const [deliverTo, setDeliverTo] = useState<string[]>([]);
  const [vehicles, setVehicles] = useState<string[]>([]);

  const { isAdmin } = useTenant();

  const columns: DataTableColDef<BookingListItem, unknown, BookingColumnId>[] =
    [
      {
        id: "booking",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Booking" />
        ),
        accessorFn: (row) => row,
        cell: BookingCell,
      },
      {
        id: "status",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Status" />
        ),
        accessorFn: (row) => row,
        cell: StatusCell,
      },
      {
        id: "matches",
        header: "Matches",
        cell: MatchedRelocationsCell,
        isHidden: !isAdmin,
      },
      {
        id: "relocation",
        header: "Relocation",
        cell: ({ row }) => {
          return <RelocationCell booking={row.original} />;
        },
      },
      {
        id: "dates",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Dates" />
        ),
        accessorFn: (row) => row,
        size: 200,
        cell: DateCell,
      },
      {
        id: "customer",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Driver" />
        ),
        accessorFn: (row) => row,
        cell: CustomerCell,
      },
      {
        id: "pax",
        header: "Pax",
        cell: ({ row }) => (
          <div className="text-gray-500">
            {row.original.number_of_travellers}
          </div>
        ),
      },
      {
        id: "trip",
        header: "Trip",
        cell: TripCell,
      },
      {
        id: "created_at",
        accessorFn: (row) => row,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Created" />
        ),
        cell: ({ row }) => (
          <TimeSinceNow
            className="text-xs text-gray-500"
            time={row.original.created_at}
          />
        ),
      },
    ];

  const [localStatus, setLocalStatus] = useState<BookingStatus[] | undefined>(
    defaultEnabledStatuses ?? [],
  );

  const status = statuses ?? localStatus;

  if (status === undefined) {
    throw new Error("Status is undefined");
  }

  const setStatus = (status: BookingStatus[]) => {
    if (onStatusChange) {
      onStatusChange(status);
    } else {
      setLocalStatus(status);
    }
  };

  return (
    <DataTable
      id={id}
      title={title}
      hiddenColumns={hiddenColumns}
      getActions={getActions}
      document={bookingListQuery}
      columns={columns}
      accessor={(data) => data.bookings}
      filters={
        <div className="flex flex-wrap gap-2">
          <MultiSelectFilter
            label="Status"
            options={options.filter((option) => {
              return option.value !== "vip" || isAdmin;
            })}
            selected={getStatusTypes(status)}
            onChange={(values) => {
              const newStatus = values.flatMap(
                (value) => statusTypeToStatusMap[value],
              );
              setStatus(newStatus);
            }}
          />
          <OfficeFilter
            placeholder="Departing from"
            selected={departFrom}
            onChange={setDepartFrom}
          />
          <OfficeFilter
            placeholder="Delivering to"
            selected={deliverTo}
            onChange={setDeliverTo}
          />
          <VehicleFilter
            placeholder="Vehicle"
            selected={vehicles}
            onChange={setVehicles}
          />
        </div>
      }
      getQueryVariables={({ pagination, sorting, search }) => {
        return {
          supplierId: supplier?.id,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          departFrom: departFrom.length ? departFrom : undefined,
          deliverTo: deliverTo.length ? deliverTo : undefined,
          vehicleIds: vehicles.length ? vehicles : undefined,
          search,
          status: status.length > 0 ? status : undefined,
          orderBy: search
            ? undefined
            : sorting.length
              ? sorting.map((s) => {
                  const column = sortMap[s.id];

                  if (!column) {
                    throw new Error("No sort configured for " + s.id);
                  }

                  return {
                    column,
                    order: s.desc ? SortOrder.Desc : SortOrder.Asc,
                  };
                })
              : [
                  {
                    order: SortOrder.Desc,
                    column: QueryBookingsOrderByColumn.CreatedAt,
                  },
                ],
          ...queryVariables,
        } satisfies BookingListQueryVariables;
      }}
    />
  );
}

export function RelocationCell({ booking }: { booking: BookingListItem }) {
  const relocation = booking.relocation;
  const { hasSupplier } = useGlobalSupplier();

  return (
    <RelocationDetailsDialog relocation={booking.relocation}>
      <p>
        <Link
          title={relocation.id}
          className="truncate text-blue-500 underline"
          to="/relocations/$relocationId"
          params={{
            relocationId: relocation.id,
          }}
        >
          {relocation.departureOffice.name} to {relocation.deliveryOffice.name}
        </Link>
      </p>
      {!hasSupplier ? (
        <p className="text-xs font-bold">{relocation.supplier.name}</p>
      ) : null}
      <p className="text-xs text-gray-500">{relocation.vehicle.name}</p>
      <ul>
        {relocation.inclusions.map(({ id, type, value, description }) => {
          const Icon = inclusionIconMap[type];

          return (
            <li
              key={id}
              className="flex items-center space-x-2 text-xs text-gray-500"
            >
              <Icon className="h-4 w-4 flex-shrink-0 text-yellow-400" />
              {value ? (
                <span>{formatCurrency(value, relocation.currency)}</span>
              ) : null}
              <div
                className="line-clamp-1 truncate whitespace-pre-line"
                dangerouslySetInnerHTML={{
                  __html: description ?? "--",
                }}
              />
            </li>
          );
        })}
      </ul>
    </RelocationDetailsDialog>
  );
}

function MatchedRelocationsCell({ row }: CellContext<BookingListItem, any>) {
  const { open } = useDialog(BookingFindRelocationDialog);

  return (
    <TextButton
      intent="primary"
      onClick={() => {
        open({
          bookingId: row.original.id,
        });
      }}
    >
      {row.original.matchedRelocationsCount}
    </TextButton>
  );
}

function BookingCell({ row, table }: CellContext<BookingListItem, any>) {
  const booking = row.original;

  const hasLineExpired = booking.relocationLineReference?.line.expires_at
    ? dayjs(booking.relocationLineReference?.line.expires_at, {
        utc: true,
      }).isBefore(dayjs())
    : false;

  return (
    <div className="flex flex-col">
      <p>
        <Link
          to={"/bookings/$bookingId"}
          params={{
            bookingId: booking.id,
          }}
          className="flex text-blue-500 underline"
        >
          <Highlighter searchString={table.getState().globalFilter}>
            {booking.reference}
          </Highlighter>
        </Link>
      </p>
      {booking.relocationLineReference ? (
        <>
          <p
            className={cn("truncate font-bold", {
              "text-red-500": hasLineExpired,
            })}
            title={booking.relocationLineReference.reference}
          >
            {booking.relocationLineReference?.reference}
            {hasLineExpired ? (
              <span className="text-xs font-normal"> (expired)</span>
            ) : null}
          </p>
        </>
      ) : null}
    </div>
  );
}

function StatusCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;
  const t = useTranslations("booking");

  return (
    <Badge
      color={bookingStatusColorMap[booking.status]}
      className="inline-block max-w-24"
    >
      <p className="capitalize">{t(`statusShortLabel.${booking.status}`)}</p>
      {booking.supplier_reference ? (
        <p
          className="truncate whitespace-nowrap font-bold"
          title={booking.supplier_reference ?? undefined}
        >
          {booking.supplier_reference}
        </p>
      ) : null}
    </Badge>
  );
}

function DateCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;

  const diffInHours = dayjs(booking.depart_at).diff(dayjs(), "hours");
  let diffText = "<72hrs";

  if (diffInHours < 0) {
    diffText = "expired";
  } else if (diffInHours === 0) {
    diffText = "now";
  } else if (diffInHours <= 24) {
    diffText = "<24hrs";
  } else if (diffInHours <= 48) {
    diffText = "<48hrs";
  }

  const showBadge =
    diffInHours <= 72 &&
    [
      BookingStatus.Vip,
      BookingStatus.AwaitingPayment,
      BookingStatus.AwaitingConfirmation,
    ].includes(booking.status);

  return (
    <div className="text-xs">
      <div className="flex space-x-2">
        <CopyToClipboard text={formatDateTime(booking.depart_at)} />
        {showBadge ? (
          <Badge color={diffInHours > 0 ? "amber" : "red"}>{diffText}</Badge>
        ) : null}
      </div>

      <CopyToClipboard text={formatDateTime(booking.deliver_at)} />
    </div>
  );
}

function TripCell({ row }: CellContext<BookingListItem, any>) {
  const booking = row.original;

  const t = useTranslations("relocation");
  const hireUnitLabel = t(
    `hire_unit_type.${booking.relocation.hire_unit_type}`,
  );

  let unitLabel = `${booking.discounted_units}`;
  if (booking.full_price_units > 0) {
    unitLabel += ` + ${booking.full_price_units}`;
  }
  unitLabel += ` ${hireUnitLabel}s`;

  return (
    <div className="text-xs text-gray-500">
      <p className="">{unitLabel}</p>
      <p>
        {formatDistance(
          booking.distance_allowed,
          booking.relocation.measurement,
        )}
      </p>
      <p className="">{booking.relocation.supplierInsuranceOption?.name}</p>
    </div>
  );
}

export function CustomerCell({
  row,
  table,
}: CellContext<BookingListItem, any>) {
  const booking = row.original;
  const search = table.getState().globalFilter;

  return (
    <div className="text-xs">
      <p>
        <CopyToClipboard text={booking.name}>
          <Highlighter searchString={search}>{booking.name}</Highlighter>
        </CopyToClipboard>
      </p>
      <p className="text-gray-500">
        <CopyToClipboard text={booking.email}>
          <Highlighter searchString={search}>{booking.email}</Highlighter>
        </CopyToClipboard>
      </p>
      <p className="text-gray-500">
        <CopyToClipboard text={booking.phone}>
          <Highlighter searchString={search}>{booking.phone}</Highlighter>
        </CopyToClipboard>
      </p>
      <p className="text-gray-500">
        <CopyToClipboard text={formatDate(booking.date_of_birth)} />
      </p>
    </div>
  );
}
