import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { UpdateSupplierInput } from "@/gql/graphql";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { TimezoneInput } from "@/lib/Components/Form/Inputs/TimezoneInput";
import { OpenHoursInput } from "@/app/Offices/Components/OpenHoursInput";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";

export function SupplierLogisticsScreen() {
  const record = useSupplierRecord();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const { isAdmin } = useTenant();

  return (
    <div className="space-y-6 py-6">
      {isAdmin ? (
        <RecordScreenFormCard
          initialValues={{
            email_on_line_sold: record.email_on_line_sold,
            line_sold_timezone: record.line_sold_timezone,
            relocation_lines_valid_until: record.relocation_lines_valid_until,
            line_sold_email: record.line_sold_email,
          }}
          onSubmit={async (values) => {
            await mutateAsync({
              id: record.id,
              input: values,
            });
          }}
          title="Lines"
        >
          <LineSoldForm />
        </RecordScreenFormCard>
      ) : null}

      <RecordScreenFormCard<UpdateSupplierInput>
        initialValues={{
          collect_address_on_bookings: record.collect_address_on_bookings,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Booking details"
      >
        <SwitchInput
          name="collect_address_on_bookings"
          label="Collect address"
          className="col-span-3"
          optionalLabel
          tooltip="Collect the customers address on bookings"
        />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        initialValues={{
          hours_to_confirm: record.hours_to_confirm,
          timezone: record.timezone,
          logistics_hours: record.logistics_hours,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Confirmation timeframe"
      >
        <NumberInput
          name="hours_to_confirm"
          label="Hours notice"
          inputProps={{
            min: 0,
          }}
        />
        <TimezoneInput name={"timezone"} label={"Timezone"} />

        <OpenHoursInput prefix={"logistics_hours"} />
      </RecordScreenFormCard>
    </div>
  );
}

function LineSoldForm() {
  const { values } = useForm();
  return (
    <>
      <SwitchInput
        name="email_on_line_sold"
        label="Email on line sold"
        tooltip="Should the supplier recieve an email when the line is reserved"
      />

      {values.email_on_line_sold ? (
        <>
          <TextInput
            name="line_sold_email"
            label="Line sold email"
            tooltip="The email the line sold emails will be sent too"
            type="email"
          />
          <TimezoneInput name="line_sold_timezone" label="Timezone" />

          <TextInput
            name="relocation_lines_valid_until"
            label="Email until"
            type="time"
          />
        </>
      ) : null}
    </>
  );
}
