import { SupplierNotificationSettingEventButtons } from "@/app/Suppliers/Components/SupplierNotificationSettingEventButtons";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";

export function SupplierNotificationScreen() {
  const supplier = useSupplierRecord();

  return (
    <div className="py-12">
      <SupplierNotificationSettingEventButtons supplier={supplier} />
    </div>
  );
}
