import classNames from "classnames";
import { Link } from "@/components/catalyst/link";
import { RecordScreenLink } from "@/lib/Components/Screens/RecordScreen/RecordScreen";

interface RecordScreenContentTabsProps {
  tabs: RecordScreenLink[];
}

export function RecordScreenContentTabs({
  tabs,
}: RecordScreenContentTabsProps) {
  return (
    <nav
      className="flex shrink-0 space-x-8 overflow-x-auto border-b border-gray-200"
      aria-label="Tabs"
    >
      {tabs
        .filter((t) => !t?.isHidden)
        .map((tab, idx) => (
          <Link
            key={idx}
            to={tab.to}
            params={(p) => p}
            search={(prev) => {
              return {
                ...prev,
                ...(tab.search as any),
              };
            }}
            className={classNames(
              "group flex items-center border-b-2 px-1 py-4 text-sm font-medium",
            )}
            activeOptions={{
              exact: true,
            }}
            inactiveProps={{
              className:
                "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            }}
            activeProps={() => ({
              className: "border-indigo-500 text-indigo-600",
              "aria-current": "page",
            })}
          >
            {({ isActive }) => (
              <>
                <tab.Icon
                  className={classNames(
                    isActive
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5",
                  )}
                  aria-hidden="true"
                />
                <span className="whitespace-nowrap">{tab.name}</span>

                {tab.notificationCount && tab.notificationCount > 0 ? (
                  <span className="ml-2 inline flex-shrink-0 rounded-md bg-indigo-500 px-2 text-white">
                    {tab.notificationCount}
                  </span>
                ) : null}
              </>
            )}
          </Link>
        ))}
    </nav>
  );
}
