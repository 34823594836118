import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { CreateRelocationInput } from "@/gql/graphql";
import { TripRecommendations } from "@/app/SupplierTrips/Forms/SupplierTripForm";
import { Suspense } from "react";
import { Skeleton } from "@/components/ui/skeleton";

export function RelocationTripCard() {
  const { values, isEditing } = useForm<CreateRelocationInput>();

  if (
    !isEditing ||
    !values.deliveryOffice?.connect ||
    !values.departureOffice?.connect
  ) {
    return null;
  }

  return (
    <Suspense fallback={<Skeleton className="h-[292px] w-full" />}>
      <TripRecommendations
        measurement={values.measurement}
        hireUnitType={values.hire_unit_type}
        officeAId={values.departureOffice.connect}
        officeBId={values.deliveryOffice.connect}
      />
    </Suspense>
  );
}
