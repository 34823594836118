import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import {
  SupplierNotificationSettingEvent,
  SupplierNotificationSettingType,
  UpdateSupplierInput,
} from "@/gql/graphql";
import { SupplierNotificationSettingForm } from "@/app/Suppliers/Forms/SupplierNotificationSettingForm";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { useTranslations } from "use-intl";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

type SupplierNotificationSettingDialogProps = {
  supplier: SupplierRecord;
  event: SupplierNotificationSettingEvent;
};

const validation = z.object({
  notificationSettings: z.object({
    upsert: z.array(
      z.object({
        id: z.string().optional(),
        email: z.string().email(),
        event: z.nativeEnum(SupplierNotificationSettingEvent),
        type: z.nativeEnum(SupplierNotificationSettingType),
      }),
    ),
  }),
});
export function SupplierNotificationSettingDialog({
  supplier,
  event,
}: SupplierNotificationSettingDialogProps) {
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const t = useTranslations("supplier");
  const { close } = useDialogState();
  const events =
    supplier.notificationSettings?.filter(
      (setting) => setting.event === event,
    ) ?? [];

  return (
    <GenericDialog title={`${t(`notifications.event.${event}`)} notifications`}>
      <GenericForm<UpdateSupplierInput>
        validationSchema={toFormikValidationSchema(validation)}
        initialValues={{
          notificationSettings: {
            upsert: events.map((setting) => {
              return {
                id: setting.id,
                email: setting.email,
                type: setting.type,
                event,
              };
            }),
            delete: [],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: supplier.id,
            input: values,
          });

          close();
        }}
      >
        <SupplierNotificationSettingForm event={event} />
        <DialogSubmitButton text={`Add notifications`} />
      </GenericForm>
    </GenericDialog>
  );
}
