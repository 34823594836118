import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  RelocationLineReferenceListItem,
  relocationLineReferenceListQuery,
  RelocationLineReferenceListVariables,
} from "@/app/Relocations/GraphQL/relocationLineReferenceListQuery";
import { TableId } from "@/app/Common/Utils/tableIds";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { LockIcon, UnlockIcon } from "lucide-react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { markRelocationLineReferenceAsSoldMutation } from "@/app/Relocations/GraphQL/relocationLineReferenceMutations";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";
import { Link } from "@/components/catalyst/link";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { cn } from "@/lib/utils";
import { formatDate } from "@/lib/Formatters/formatDate";
import { VehicleIcon } from "@/app/Vehicles/Components/VehicleIcon";
import { inclusionIconMap } from "@/app/Relocations/Utils/inclusionIconMap";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";

type RelocationLineColumnId =
  | "line"
  | "actions"
  | "relocation"
  | "booking"
  | "expires"
  | "sold_at";

export function RelocationLineReferenceTable({
  id,
  queryVariables,
}: {
  id: TableId;
  queryVariables?: Partial<RelocationLineReferenceListVariables>;
}) {
  const { mutateAsync } = useGqlMutation(
    markRelocationLineReferenceAsSoldMutation,
  );

  const columns: DataTableColDef<
    RelocationLineReferenceListItem,
    unknown,
    RelocationLineColumnId
  >[] = [
    {
      id: "line",
      header: "Line",
      cell: ({ row }) => {
        const reference = row.original;
        let color = "bg-green-500";

        if (reference.sold_at) {
          if (reference.booking) {
            color = "bg-yellow-500";
          } else {
            color = "bg-red-500";
          }
        }

        return (
          <div className="flex items-center">
            <span>{row.original.reference}</span>
            <span
              className={cn(color, "ml-2 inline-flex h-2 w-2 rounded-full")}
            />
          </div>
        );
      },
    },
    {
      id: "relocation",
      header: "Relocation",
      cell: ({ row }) => {
        return (
          <div>
            <ul>
              {row.original.line.relocations.map((r) => {
                return (
                  <li key={r.id}>
                    <Relocation relocation={r} />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
    {
      id: "booking",
      header: "Booking",
      cell: ({ row }) =>
        row.original.booking ? (
          <ModelLinkCell
            to="/bookings/$bookingId"
            params={{
              bookingId: row.original.booking.id,
            }}
          >
            {row.original.booking?.reference}
          </ModelLinkCell>
        ) : null,
    },
    {
      id: "sold_at",
      header: "Sold",
      accessorFn: (data) => data.sold_at,
      cell: ({ row }) => (
        <TimeSinceNow className="text-gray-500" time={row.original.sold_at} />
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        if (row.original.sold_at) {
          return (
            <TextButton
              intent="danger"
              onClick={() => {
                return mutateAsync({
                  id: row.original.id,
                  sold: false,
                });
              }}
              Icon={UnlockIcon}
            >
              release
            </TextButton>
          );
        }

        return (
          <div>
            <TextButton
              onClick={() => {
                return mutateAsync({
                  id: row.original.id,
                  sold: true,
                });
              }}
              Icon={LockIcon}
            >
              sold
            </TextButton>
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      id={id}
      searchable={false}
      document={relocationLineReferenceListQuery}
      accessor={(a) => a.relocationLineReferences}
      columns={columns}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          orderByReference: true,
          ...queryVariables,
        };
      }}
    />
  );
}

function Relocation({
  relocation,
}: {
  relocation: RelocationLineReferenceListItem["line"]["relocations"][number];
}) {
  return (
    <div className="flex items-center space-x-2">
      <VehicleIcon model={relocation.vehicle} />
      <div className="w-[200px] overflow-hidden">
        <p>
          <Link
            title={relocation.id}
            className="truncate text-blue-500 underline"
            to={"/relocations/$relocationId"}
            params={{
              relocationId: relocation.id,
            }}
          >
            {relocation.departureOffice.name} to{" "}
            {relocation.deliveryOffice.name}
            {` (${relocation.id})`}
          </Link>
        </p>
        <p className="text-xs text-gray-500">{relocation.vehicle.name}</p>
        <p className="text-xs text-gray-500">
          {`${formatDate(relocation.available_from_date)} - ${formatDate(
            relocation.available_to_date,
          )}`}
        </p>
        <ul>
          {relocation.inclusions.map(({ id, type, value, description }) => {
            const Icon = inclusionIconMap[type];

            return (
              <li
                key={id}
                className="flex items-center space-x-2 text-xs text-gray-500"
              >
                <Icon className="h-4 w-4 flex-shrink-0 text-yellow-400" />
                {value ? (
                  <span>{formatCurrency(value, relocation.currency)}</span>
                ) : null}
                <div
                  className="line-clamp-1 truncate whitespace-pre-line"
                  dangerouslySetInnerHTML={{
                    __html: description ?? "--",
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
