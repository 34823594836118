import { escapeRegExp } from "lodash";
import { Fragment } from "react";

export function Highlighter({
  children,
  searchString,
}: {
  children: string;
  searchString: string;
}) {
  const pattern = new RegExp(`(${escapeRegExp(searchString)})`, "i");
  const parts = children.split(pattern);

  if (searchString) {
    return parts.map((part, index) =>
      pattern.test(part) ? (
        <mark key={index}>{part}</mark>
      ) : (
        <Fragment key={index}>{part}</Fragment>
      ),
    );
  } else {
    return children;
  }
}
