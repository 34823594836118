import { createRoute } from "@tanstack/react-router";
import { ReportScreen } from "@/app/Reports/Screens/ReportScreen";
import { ReportSelectionScreen } from "@/app/Reports/Screens/ReportSelectionScreen";
import { ReportRelocationScreen } from "@/app/Reports/Screens/ReportRelocationScreen";
import { ReportBookingScreen } from "@/app/Reports/Screens/ReportBookingScreen";
import { appLayoutRoute } from "@/routes";
import { ReportSalesScreen } from "@/app/Reports/Screens/ReportSalesScreen";
import { z } from "zod";
import dayjs from "dayjs";
import { ReportPulseScreen } from "@/app/Reports/Screens/ReportPulseScreen";

export const reportRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/reports",
  component: ReportScreen,
});
export const reportSelectionRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/",
  component: ReportSelectionScreen,
});

const reportBookingSearchParams = z.object({
  supplierIds: z.array(z.string()).optional(),
  startDate: z
    .string()
    .catch(dayjs().subtract(30, "days").format("YYYY-MM-DD")),
  endDate: z.string().catch(dayjs().format("YYYY-MM-DD")),
});
export const reportBookingsRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/bookings",
  component: ReportBookingScreen,
  validateSearch: reportBookingSearchParams,
});

export const reportRelocationsRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/relocations",
  component: ReportRelocationScreen,
});

export const reportSalesRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/sales",
  component: ReportSalesScreen,
});

export const reportSystemsRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/systems",
  component: ReportPulseScreen,
});
