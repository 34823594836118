import {
  CalendarDaysIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  MapIcon,
  MapPinIcon,
  MegaphoneIcon,
  PresentationChartLineIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
  SquaresPlusIcon,
  TruckIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { LinkProps, Outlet } from "@tanstack/react-router";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { FC, Suspense } from "react";
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarHeading,
  SidebarItemLink,
  SidebarLabel,
  SidebarSection,
} from "@/components/catalyst/sidebar";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

type VisibleTo = "all" | "supplier" | "no-supplier";

export function SettingsScreen() {
  const subNavigation: {
    name: string;
    to: LinkProps["to"];
    icon: FC<any>;
    visibleTo: VisibleTo;
  }[] = [
    {
      name: "General",
      to: "/settings",
      icon: Cog6ToothIcon,
      visibleTo: "supplier",
    },
    {
      name: "Vehicles",
      to: "/settings/vehicles",
      icon: TruckIcon,
      visibleTo: "supplier",
    },
    {
      name: "Offices",
      to: "/settings/offices",
      icon: MapPinIcon,
      visibleTo: "supplier",
    },
    {
      name: "Opens hours",
      icon: CalendarDaysIcon,
      to: "/settings/availability",
      visibleTo: "supplier",
    },
    {
      name: "Insurance",
      icon: ShieldCheckIcon,
      to: "/settings/insurance",
      visibleTo: "supplier",
    },
    {
      name: "Extras",
      icon: ShoppingCartIcon,
      to: "/settings/extras",
      visibleTo: "supplier",
    },
    {
      name: "Notifications",
      icon: MegaphoneIcon,
      to: "/settings/notifications",
      visibleTo: "supplier",
    },
    {
      name: "Billing",
      icon: CurrencyDollarIcon,
      to: "/settings/billing",
      visibleTo: "supplier",
    },
    {
      name: "Integrations",
      icon: SquaresPlusIcon,
      to: "/settings/integrations",
      visibleTo: "supplier",
    },
    {
      name: "Reporting",
      icon: PresentationChartLineIcon,
      to: "/settings/reports",
      visibleTo: "all",
    },
    {
      name: "Team",
      icon: UsersIcon,
      to: "/settings/organisation/organization-members",
      visibleTo: "all",
    },
  ];

  const adminNavigation = [
    {
      name: "Cities",
      to: "/settings/cities",
      icon: MapIcon,
    },
    {
      name: "Organisations",
      to: "/settings/organisations",
      icon: UserGroupIcon,
    },
    {
      name: "Rates",
      to: "/settings/default-rates",
      icon: CurrencyDollarIcon,
    },
  ];

  const { supplier } = useGlobalSupplier();
  const { isAdmin } = useTenant();

  return (
    <div className="scroll-gutter-stable h-full overflow-y-auto bg-gray-50">
      <div className="mx-auto w-full max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-5 lg:px-8 lg:py-12">
        <aside className="relative px-2 py-6 sm:px-6 lg:col-span-2 lg:px-0 lg:py-0">
          <Sidebar>
            <SidebarBody>
              <SidebarSection>
                {subNavigation
                  .filter((item) => {
                    if (item.visibleTo === "all") {
                      return true;
                    }

                    if (item.visibleTo === "supplier" && supplier) {
                      return true;
                    }

                    return item.visibleTo === "no-supplier" && !supplier;
                  })
                  .map((item) => (
                    <SidebarItemLink
                      key={item.name}
                      to={item.to}
                      linkClassName="text-zinc-900 data-[hover]:bg-zinc-950/5 data-[slot=icon]:*:data-[hover]:fill-zinc-950 data-[slot=icon]:*:data-[current]:fill-black"
                      indicatorClassName="bg-gray-900"
                      activeProps={{
                        "aria-current": "page",
                      }}
                      fuzzyMatch={false}
                      activeOptions={{
                        exact: true,
                      }}
                    >
                      <item.icon className="size-4" />
                      <SidebarLabel>{item.name}</SidebarLabel>
                    </SidebarItemLink>
                  ))}

                {isAdmin ? (
                  <>
                    <SidebarDivider />
                    <SidebarHeading>Admin section</SidebarHeading>
                    {adminNavigation.map((item) => (
                      <SidebarItemLink
                        key={item.name}
                        to={item.to as any}
                        search={{}}
                        linkClassName="text-zinc-900 data-[hover]:bg-zinc-950/5 data-[slot=icon]:*:data-[hover]:fill-zinc-950 data-[slot=icon]:*:data-[current]:fill-black"
                        indicatorClassName="bg-gray-900"
                        activeProps={{
                          "aria-current": "page",
                        }}
                        activeOptions={{
                          exact: true,
                        }}
                      >
                        <item.icon className="size-4" />
                        <SidebarLabel>{item.name}</SidebarLabel>
                      </SidebarItemLink>
                    ))}
                  </>
                ) : null}
              </SidebarSection>
            </SidebarBody>
          </Sidebar>
        </aside>
        {/* Payment details */}
        <div className="p w-full space-y-6 sm:px-6 lg:col-span-9">
          <Suspense
            fallback={
              <div className="flex h-full w-full items-center justify-center">
                <Spinner className="size-5" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
