import { ContactIcon } from "@/app/Contacts/Components/ContactIcon";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import {
  ContactCreateDialog,
  ContactCreateDialogProps,
} from "@/app/Contacts/Components/ContactCreateDialog";
import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import {
  ContactListItem,
  contactListQuery,
} from "@/app/Contacts/GraphQL/contactListQuery";
import { ContactType, CreateContactInput } from "@/gql/graphql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { Badge } from "@/components/catalyst/badge";
import { contactTypeColorMap } from "@/app/Contacts/Utils/contactTypeColorMap";
import { contactRecordQuery } from "@/app/Contacts/GraphQL/contactRecordQuery";
import { Link } from "@/components/catalyst/link";
import { useTranslations } from "use-intl";

export function ContactInput({
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof contactListQuery>,
  VariablesOf<typeof contactListQuery>,
  ContactListItem,
  ResultOf<typeof contactRecordQuery>,
  VariablesOf<typeof contactRecordQuery>
> & {
  initialFixtureValues?: Partial<CreateContactInput>;
}) {
  const { openAsPromise } = useDialog<
    ContactCreateDialogProps,
    ContactListItem
  >(ContactCreateDialog);

  return (
    <ComboboxInput
      document={contactListQuery}
      accessor={(data) => data.contacts.data}
      getQueryVariables={(search) => ({
        first: 20,
        page: 1,
        search,
      })}
      recordDocument={contactRecordQuery}
      recordAccessor={(data) => data.contact}
      getViewNode={(i) =>
        i ? (
          <Link
            to="/contacts/$id"
            className="text-blue-500 underline"
            params={{
              id: i.id,
            }}
          >
            {i.name}
          </Link>
        ) : (
          "--"
        )
      }
      getKey={(item) => item.id}
      getListItemNode={(item) => <Node model={item} />}
      getInputNode={(item) => item.name}
      fixture={(search) => ({
        label: `Create '${search}'`,
        onClick() {
          return openAsPromise({
            initialValues: {
              name: search,
            },
          });
        },
      })}
      {...props}
    />
  );
}

function Node({ model }: { model: ContactListItem }) {
  const t = useTranslations("contact");

  return (
    <div className="flex items-center space-x-3">
      <ContactIcon model={model} className="size-10 flex-shrink-0" />
      <div className="flex-grow">
        <p className="">{model.name}</p>
        {[
          ContactType.Organisation,
          ContactType.Entity,
          ContactType.System,
        ].includes(model.type) ? (
          <p className="mt-1">
            <Badge className="" color={contactTypeColorMap[model.type]}>
              {t(`type.${model.type}`)}
            </Badge>
          </p>
        ) : null}
      </div>
    </div>
  );
}
