import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type BookingListItem = ResultOf<
  typeof bookingListQuery
>["bookings"]["data"][number];

export const bookingListQuery = graphql(`
  query BookingList(
    $first: Int!
    $page: Int
    $orderBy: [QueryBookingsOrderByRelationOrderByClause!]
    $status: [BookingStatus!]
    $relocationId: ID
    $supplierId: ID
    $search: String
    $whereRelocation: QueryBookingsWhereRelocationWhereHasConditions
    $where: QueryBookingsWhereWhereConditions
    $departFrom: [ID!]
    $deliverTo: [ID!]
    $vehicleIds: [ID!]
  ) {
    bookings(
      page: $page
      first: $first
      orderBy: $orderBy
      status: $status
      relocation_id: $relocationId
      supplier_id: $supplierId
      search: $search
      where: $where
      whereRelocation: $whereRelocation
      delivery_office_id: $deliverTo
      departure_office_id: $departFrom
      vehicle_id: $vehicleIds
    ) {
      data {
        id
        reference
        currency
        permalink
        name
        email
        phone
        status
        distance_allowed
        currency
        date_of_birth
        created_at
        depart_at
        deliver_at
        pay_now_total_amount
        matchedRelocationsCount
        supplier_reference
        number_of_travellers
        discounted_units
        full_price_units
        customer_note
        pay_later_additional_charges_amount
        purchasableExtras {
          id
          quantity
          supplierPurchasableExtra {
            id
            name
            description
            units_purchasable
            unit_price
          }
        }
        relocation {
          id
          hire_unit_type
          measurement
          currency
          distance_allowed
          available_from_date
          available_to_date
          hire_units_allowed
          extra_hire_units_allowed
          reference
          supplier {
            id
            name
          }
          vehicle {
            id
            name
          }
          departureOffice {
            id
            name
          }
          deliveryOffice {
            id
            name
          }
          inclusions {
            id
            type
            description
            value
          }
          supplierInsuranceOption {
            ...SupplierInsuranceOptionRecordFields
          }
        }
        relocationLineReference {
          id
          reference
          created_at
          sold_at
          line {
            id
            expires_at
          }
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
