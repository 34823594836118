import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import {
  InvoiceStatus,
  UpdateInvoiceInput,
  UpsertPayableLineItemInput,
} from "@/gql/graphql";
import { InvoiceDetailsForm } from "@/app/Invoices/Forms/InvoiceDetailsForm";
import { PayableLineItemTable } from "@/app/Invoices/Components/ViewInvoiceLineItems";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { InvoicePaymentFeed } from "@/app/Invoices/Components/InvoicePaymentFeed";
import { AddInvoicePaymentButton } from "@/app/Invoices/Components/AddInvoicePaymentButton";
import { InvoiceLineItemsForm } from "@/app/Invoices/Forms/InvoiceLineItemsForm";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { PersonnelCard } from "@/lib/Components/DescriptionList/PersonnelCard";
import {
  BriefcaseBusiness,
  MailIcon,
  MapPinIcon,
  PhoneIcon,
} from "lucide-react";
import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { ContactEditDialog } from "@/app/Contacts/Components/ContactEditDialog";
import { PencilIcon } from "@heroicons/react/24/outline";
import { formatDate } from "@/lib/Formatters/formatDate";
import { useTranslations } from "use-intl";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateInvoiceMutation } from "@/app/Invoices/GraphQL/invoiceMutations";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";
import { Badge } from "@/components/catalyst/badge";
import { contactTypeColorMap } from "@/app/Contacts/Utils/contactTypeColorMap";
import { Link } from "@/components/catalyst/link";

export function InvoiceDetailScreen() {
  const record = useInvoiceRecord();
  const { mutateAsync } = useGqlMutation(updateInvoiceMutation);
  const { isAdmin } = useTenant();
  const { open } = useDialog(ContactEditDialog);
  const t = useTranslations("contact");

  return (
    <div className="space-y-12 py-6">
      {isAdmin || record.status !== InvoiceStatus.Draft ? (
        <RecordScreenCard
          className="mb-20"
          title={"Payments"}
          buttons={isAdmin ? <AddInvoicePaymentButton /> : null}
        >
          <InvoicePaymentFeed />
        </RecordScreenCard>
      ) : null}

      <RecordScreenFormCard<UpdateInvoiceInput>
        isEditable={isAdmin}
        initialValues={{
          payee: {
            connect: record.payee.id,
          },
          invoice_date: record.invoice_date,
          due_date: record.due_date,
          purchase_order_number: record.purchase_order_number,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Details"
        view={
          <div className="grid grid-cols-2 gap-6">
            <PersonnelCard
              badge={
                <Badge color={contactTypeColorMap[record.payee.type]}>
                  {t(`type.${record.payee.type}`)}
                </Badge>
              }
              items={[
                {
                  Icon: BriefcaseBusiness,
                  label: record.payee.company_number ?? "--",
                },
                {
                  Icon: MailIcon,
                  label: record.payee.billingEmail?.email ?? "--",
                },
                {
                  label: record.payee.billingPhone?.phone ?? "--",
                  Icon: PhoneIcon,
                },
                {
                  label:
                    record.payee.billingAddress?.formatted_address_line_1 ??
                    "--",
                  Icon: MapPinIcon,
                },
              ]}
              primaryItem={{
                label: "Payee",
                value: (
                  <div className="group">
                    <span className="truncate">
                      <Link
                        className="underline"
                        to="/contacts/$id"
                        params={{
                          id: record.payee.id,
                        }}
                      >
                        {record.payee.legal_name ?? record.payee.name}
                      </Link>
                    </span>
                    <button
                      type="button"
                      onClick={() => open({ contactId: record.payee.id })}
                      className="ml-2 text-gray-400 group-hover:text-gray-500"
                    >
                      <PencilIcon className="h-3 w-3" />
                    </button>
                  </div>
                ),
              }}
              linkLabel="View payee"
              toOptions={{
                to: "/contacts/$id",
                params: {
                  id: record.payee.id,
                },
              }}
            />
            <DescriptionList
              items={[
                {
                  key: "PO #",
                  value: record.purchase_order_number ?? "--",
                },
                {
                  key: "Invoice Date",
                  value: formatDate(record.invoice_date),
                },
                {
                  key: "Due Date",
                  value: formatDate(record.due_date),
                },
              ]}
            />
          </div>
        }
      >
        <InvoiceDetailsForm invoice={record} />
      </RecordScreenFormCard>

      <RecordScreenFormCard<UpdateInvoiceInput>
        enableReinitialize={true}
        isEditable={isAdmin}
        initialValues={{
          lineItems: {
            upsert: record.lineItems.map((lineItem) => {
              return {
                id: lineItem.id,
                unit_amount: lineItem.unit_amount,
                quantity: lineItem.quantity,
                description: lineItem.description,
                tax_type: lineItem.tax_type,
                fulfills: lineItem.fulfills
                  ? {
                      update: {
                        id: lineItem.fulfills?.id ?? null!,
                      },
                    }
                  : undefined,
              } satisfies UpsertPayableLineItemInput;
            }),
            delete: [],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="Line items"
        view={<PayableLineItemTable />}
      >
        <InvoiceLineItemsForm
          payee={record.payee}
          currency={record.currency}
          invoice={record}
        />
      </RecordScreenFormCard>
    </div>
  );
}
