import { graphql } from "@/gql";

export const allCitiesQuery = graphql(`
  query AllCities {
    allCities {
      id
      name
      region
      state
    }
  }
`);
