import { CellContext } from "@tanstack/react-table";
import {
  RelocationInclusionFieldsFragment,
  RelocationInclusionType,
  RelocationListQueryVariables,
} from "@/gql/graphql";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import {
  BookingChangeRelocationDialog,
  BookingChangeRelocationDialogProps,
} from "@/app/Bookings/Components/BookingChangeRelocationDialog";
import { cn } from "@/lib/utils";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  formatCurrency,
  formatDuration,
} from "@/lib/Formatters/formatCurrency";
import { inclusionIconMap } from "@/app/Relocations/Maps/inclusionIconMap";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Link } from "@/components/catalyst/link";
import { IconAndText } from "@/app/Relocations/Components/RelocationSummaryCard";
import { ArmchairIcon, CarIcon } from "lucide-react";
import {
  Cog8ToothIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import { toast } from "sonner";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  RelocationListItem,
  relocationListQuery,
} from "@/app/Relocations/GraphQL/relocationListQuery";
import { relocationDeliveryOpenHoursQuery } from "@/app/Relocations/GraphQL/relocationOpenHoursQuery";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { useTranslations } from "use-intl";
import { Badge } from "@/components/catalyst/badge";
import { RelocationReferenceCell } from "@/app/Relocations/Components/Cells/RelocationReferenceCell";

type MatchingRelocationCellId =
  | "relocation"
  | "supplier"
  | "vehicle"
  | "inclusions"
  | "lines"
  | "actions";
export function BookingMatchingRelocationsTable({
  booking,
  onAssign,
  queryVariables,
  title,
}: {
  booking: BookingRecord;
  onAssign?: (relocation: RelocationListItem) => void;
  queryVariables?: Partial<RelocationListQueryVariables>;
  title: string;
}) {
  const columns: DataTableColDef<
    RelocationListItem,
    unknown,
    MatchingRelocationCellId
  >[] = [
    {
      id: "relocation",
      header: "Relocation",
      accessorFn: (model) => model,
      cell: RelocationCell,
      meta: {
        booking,
      },
    },
    {
      id: "supplier",
      header: "Supplier",
      cell: SupplierCell,
      meta: {
        booking,
      },
    },
    {
      id: "vehicle",
      header: "Vehicle",
      cell: VehicleCell,
      meta: {
        booking,
      },
    },
    {
      id: "inclusions",
      header: "Inclusions",
      cell: InclusionsCell,
      meta: {
        booking,
      },
    },
    {
      id: "lines",
      header: "Lines",
      cell: RelocationReferenceCell,
      meta: {
        booking,
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ReassignCell,
      meta: {
        booking,
        onAssign,
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      document={relocationListQuery}
      title={title}
      getQueryVariables={({ pagination }) => {
        return {
          page: pagination.pageIndex,
          first: pagination.pageSize,
          ...queryVariables,
        };
      }}
      accessor={(data) => data.relocations}
      id="matching-relocations"
    />
  );
}

function SupplierCell({ row, column }: CellContext<RelocationListItem, any>) {
  const relocation = row.original;
  const booking: BookingRecord = (column.columnDef.meta as any)?.booking;

  const hasSameSupplier =
    booking.relocation.supplier.id === relocation.supplier.id;

  return (
    <div>
      <Link
        to={"/suppliers/$id"}
        params={{
          id: relocation.supplier.id,
        }}
        className={cn("text-blue-500 underline", {
          "bg-yellow-200": !hasSameSupplier,
        })}
      >
        {relocation.supplier.name}
      </Link>
    </div>
  );
}

function RelocationCell({ row, column }: CellContext<RelocationListItem, any>) {
  const relocation = row.original;
  const booking: BookingRecord = (column.columnDef.meta as any)?.booking;

  const hasSameCities =
    booking.relocation!.departureCity.id === relocation.departureCity.id &&
    booking.relocation!.deliveryCity.id === relocation.deliveryCity.id;

  const departAt = dayjs(booking.depart_at);
  const canPickup = departAt.isAfter(
    dayjs(row.original.available_from_date).startOf("day"),
  );
  const deliverAt = dayjs(booking.deliver_at);
  const canDropoff = deliverAt.isBefore(
    dayjs(row.original.available_to_date).endOf("day"),
  );

  const { data, isLoading } = useGqlQuery(relocationDeliveryOpenHoursQuery, {
    relocationId: relocation.id,
    departureDate: departAt.format("YYYY-MM-DD"),
    departureTime: departAt.format("HH:mm"),
  });

  const isDropoffOpenOnDeliveryDate = isLoading
    ? true
    : !!data?.relocationDeliveryOpenHours?.find((openHours) => {
        return openHours.date === deliverAt.format("YYYY-MM-DD");
      });

  return (
    <div>
      <p>
        <Link
          to={"/relocations/$relocationId"}
          params={{
            relocationId: relocation.id,
          }}
          className={cn("text-blue-500 underline", {
            "bg-yellow-200": !hasSameCities,
          })}
        >
          <span>{relocation.departureCity.name}</span>
          <span>{" - "}</span>
          <span>{relocation.deliveryCity.name}</span>{" "}
          <span>({relocation.id})</span>
        </Link>
      </p>
      <p className="font-bold">
        <span
          className={cn({
            "bg-yellow-200": !canPickup,
          })}
        >
          {formatDate(row.original.available_from_date)}
        </span>
        <span>{" - "}</span>
        <span
          className={cn({
            "bg-yellow-200": !canDropoff,
          })}
        >
          {formatDate(row.original.available_to_date)}
        </span>
      </p>
      {!isDropoffOpenOnDeliveryDate ? (
        <Badge color="yellow">
          <ExclamationTriangleIcon className="inline size-3.5" />{" "}
          {formatDate(deliverAt)} is not available for dropoff
        </Badge>
      ) : null}
      <p>
        {formatDuration(
          booking.discounted_units,
          booking.full_price_units,
          booking.relocation.hire_unit_type,
        )}
      </p>
      <p>
        {formatCurrency(relocation.hire_unit_rate, relocation.currency)}
        {" / "}
        {formatCurrency(relocation.extra_hire_unit_rate, relocation.currency)}
      </p>
    </div>
  );
}

function VehicleCell({ row, column }: CellContext<RelocationListItem, any>) {
  const relocation = row.original;
  const vehicle = relocation.vehicle;
  const t = useTranslations("vehicle");

  const booking: BookingRecord = (column.columnDef.meta as any)?.booking;

  const hasSameType = vehicle.type === booking.relocation.vehicle.type;
  const hasSameTransmission =
    vehicle.transmission === booking.relocation.vehicle.transmission;
  const hasSameSeatbelts =
    vehicle.seatbelts >= booking.relocation.vehicle.seatbelts;

  const hasSameVehicle = booking.relocation.vehicle.id === vehicle.id;

  return (
    <div>
      <Link
        to={"/vehicles/$id"}
        params={{
          id: vehicle.id,
        }}
        className={cn("text-blue-500 underline", {
          "bg-yellow-200": !hasSameVehicle,
        })}
      >
        {vehicle.name}
      </Link>
      <ul>
        <IconAndText
          Icon={CarIcon}
          text={t(`type.${vehicle.type}`)}
          highlight={!hasSameType}
        />
        <IconAndText
          Icon={Cog8ToothIcon}
          text={t(`transmission.${vehicle.transmission}`)}
          highlight={!hasSameTransmission}
        />
        <IconAndText
          Icon={ArmchairIcon}
          text={relocation.vehicle.seatbelts}
          highlight={!hasSameSeatbelts}
        />
      </ul>
    </div>
  );
}

function InclusionsCell({ row, column }: CellContext<RelocationListItem, any>) {
  const relocation = row.original;
  const booking: BookingRecord = (column.columnDef.meta as any)?.booking;

  const oldFuel = sumInclusionValue(booking.relocation?.inclusions ?? []);
  const newFuel = sumInclusionValue(relocation.inclusions);
  const isFuelLower = newFuel < oldFuel;
  const isFuelHigher = newFuel > oldFuel;

  return (
    <div>
      <ul>
        {relocation.inclusions.map((inclusion) => {
          const Icon = inclusionIconMap[inclusion.type];

          return (
            <li key={inclusion.id} className="flex items-center space-x-2">
              <Icon className="h-4 w-4 text-yellow-500" />
              <span>
                {formatCurrency(inclusion.value, relocation.currency)}
              </span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoCircledIcon className="h-4 w-4 text-yellow-500" />
                  </TooltipTrigger>
                  <TooltipContent className="p-2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: inclusion.description ?? "--",
                      }}
                    />
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </li>
          );
        })}
      </ul>

      {isFuelLower ? (
        <div className="mt-2">
          <Badge color="red">Less fuel</Badge>
        </div>
      ) : null}

      {isFuelHigher ? (
        <div className="mt-2">
          <Badge color="green">More fuel</Badge>
        </div>
      ) : null}
    </div>
  );
}

function sumInclusionValue(inclusions: RelocationInclusionFieldsFragment[]) {
  return inclusions.reduce((i, prev) => {
    return (
      i + (prev.type === RelocationInclusionType.Fuel ? (prev.value ?? 0) : 0)
    );
  }, 0);
}

function ReassignCell({ row, column }: CellContext<RelocationListItem, any>) {
  const relocation = row.original;
  const { openAsPromise } = useDialog<
    BookingChangeRelocationDialogProps,
    BookingRecord
  >(BookingChangeRelocationDialog);
  const booking = (column.columnDef.meta as any).booking;
  const onAssign = (column.columnDef.meta as any)?.onAssign;

  return (
    <TextButton
      intent="primary"
      onClick={async () => {
        try {
          const newRelocation = await openAsPromise({
            booking,
            newRelocation: relocation,
          });
          onAssign?.(newRelocation);
          toast("Relocation assigned", {
            action: {
              label: "Email supplier",
              onClick: () => alert("TODO"),
            },
          });
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
          return;
        }
      }}
    >
      assign
    </TextButton>
  );
}
