import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Link } from "@/components/catalyst/link";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";

export function BookingNoPaymentWarning() {
  const { isAdmin } = useTenant();

  const booking = useBookingRecord();

  if (!isAdmin) {
    return null;
  }

  const totalPayment = booking.customerPayments.reduce((acc, payment) => {
    if (payment.status === "PENDING") {
      return acc + payment.amount;
    }

    return acc + payment.amount_paid;
  }, 0);

  if (totalPayment > 0) {
    return null;
  }

  return (
    <WarningBanner className="rounded-md shadow-sm">
      There is no{" "}
      <Link
        className="underline"
        to={"/bookings/$bookingId/payments"}
        params={{
          bookingId: booking.id,
        }}
      >
        payment
      </Link>{" "}
      for this booking
    </WarningBanner>
  );
}
