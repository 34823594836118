import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type TripListItem = ResultOf<
  typeof tripListQuery
>["trips"]["data"][number];
export const tripListQuery = graphql(`
  query TripList(
    $first: Int!
    $page: Int!
    $cityIds: [ID!]
    $order: [QueryTripsOrderRelationOrderByClause!]
  ) {
    trips(first: $first, page: $page, city_ids: $cityIds, order: $order) {
      data {
        id
        distance
        duration
        cityA {
          id
          name
        }
        cityB {
          id
          name
        }
        abImage {
          ...MediaFields
        }
        baImage {
          ...MediaFields
        }
        landscape_image {
          ...MediaFields
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
