import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ModuleRegistry,
  RowSelectionOptions,
  themeQuartz,
} from "ag-grid-community";
import { useMemo } from "react";
import {
  AllEnterpriseModule,
  IntegratedChartsModule,
} from "ag-grid-enterprise";
import {
  BookingStatus,
  Currency,
  QueryBookingsOrderByColumn,
  QueryBookingsWhereColumn,
  QueryBookingsWhereRelocationColumn,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";
import { AgChartsCommunityModule } from "ag-charts-community";
import dayjs from "dayjs";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import {
  useFetchData,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import {
  ReportBookingListItem,
  reportBookingListQuery,
} from "@/app/Reports/Components/ReportBookings/reportBookingsQuery";
import { useTranslations } from "use-intl";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { allCitiesQuery } from "@/app/Reports/Components/ReportBookings/allCitiesQuery";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

ModuleRegistry.registerModules([
  AllEnterpriseModule,
  IntegratedChartsModule.with(AgChartsCommunityModule),
]);

const imoovaTheme = themeQuartz.withParams({
  browserColorScheme: "light",
  fontFamily: {
    googleFont: "Inter",
  },
  fontSize: 12,
  headerFontSize: 14,
  spacing: 5,
});

const defaultColDef = {
  floatingFilter: true,
};

const sidebar = {
  toolPanels: ["columns", "filters"],
};

export function ReportBookingsTable({ ref }: { ref: any }) {
  const { startDate, endDate, supplierIds } = useSearch({
    from: "/_app/reports/bookings",
  });
  const t = useTranslations("booking");

  const fetcher = useFetchData(reportBookingListQuery);

  const variables: VariablesOf<typeof reportBookingListQuery> = {
    first: 200,
    whereRelocation: supplierIds?.length
      ? {
          column: QueryBookingsWhereRelocationColumn.SupplierId,
          operator: SqlOperator.In,
          value: supplierIds,
        }
      : undefined,
    where: {
      AND: [
        {
          column: QueryBookingsWhereColumn.CreatedAt,
          operator: SqlOperator.Between,
          value: [startDate, endDate],
        },
        {
          column: QueryBookingsWhereColumn.Status,
          operator: SqlOperator.Neq,
          value: BookingStatus.AwaitingPayment,
        },
      ],
    },
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QueryBookingsOrderByColumn.CreatedAt,
      },
    ],
  };

  const { data } = useSuspenseQuery({
    queryKey: ["ReportBookings", variables],
    queryFn: async () => {
      const bookings: ReportBookingListItem[] = [];
      let page = 1;
      let hasMorePages = true;

      while (hasMorePages) {
        const res = await fetcher({
          page,
          ...variables,
        });

        bookings.push(...res.bookings.data);
        page++;

        hasMorePages = res.bookings.paginatorInfo.hasMorePages;
      }

      return bookings;
    },
  });

  const { data: allCitiesRes } = useSuspenseGqlQuery(allCitiesQuery, {});
  const cities = allCitiesRes.allCities;
  const { isAdmin } = useTenant();

  const columns: ColDef<ReportBookingListItem>[] = useMemo(() => {
    const allColumns: (ColDef<ReportBookingListItem> & {
      isAdmin?: boolean;
    })[] = [
      {
        field: "relocation.supplier.name",
        headerName: "Supplier",
        filter: true,
        enableRowGroup: true,
      },
      {
        field: "reference",
        filter: "agTextColumnFilter",
      },
      {
        field: "status",
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        valueFormatter: (params) => {
          if (params.value === undefined) {
            return "";
          }

          return t(`status.${params.value as BookingStatus}`);
        },
        filterParams: {
          valueFormatter: (params: any) => {
            return t(`status.${params.value as BookingStatus}`);
          },
        },
      },
      {
        context: {
          isAdmin: true,
        },
        field: "total_booking_value",
        headerName: "Booking value",
        enableValue: true,
        valueFormatter: (value) => {
          if (value.value === undefined) {
            return "";
          }

          return formatCurrency(
            value.value ?? 0,
            value.data?.currency ?? Currency.Aud,
          );
        },
      },
      {
        context: {
          isAdmin: true,
        },
        field: "relocation.booking_fee_amount",
        headerName: "Booking fee",
        enableValue: true,
        valueFormatter: (value) => {
          if (value.value === undefined) {
            return "";
          }

          return formatCurrency(
            value.value ?? 0,
            value.data?.currency ?? Currency.Aud,
          );
        },
      },
      {
        context: {
          isAdmin: true,
        },
        field: "relocation.holding_deposit_amount",
        headerName: "Holding deposit",
        enableValue: true,
        valueFormatter: (value) => {
          if (value.value === undefined) {
            return "";
          }

          return formatCurrency(
            value.value ?? 0,
            value.data?.currency ?? Currency.Aud,
          );
        },
      },
      {
        field: "relocation.charge_per_move_amount",
        headerName: "Supplier charge",
        enableValue: true,
        valueFormatter: (value) => {
          if (value.value === undefined) {
            return "";
          }

          return formatCurrency(
            value.value ?? 0,
            value.data?.currency ?? Currency.Aud,
          );
        },
      },
      {
        context: {
          isAdmin: true,
        },
        field: "pay_now_full_price_units_deposit_amount",
        headerName: "Extra day commission",
        enableValue: true,
        valueFormatter: (value) => {
          if (value.value === undefined) {
            return "";
          }

          return formatCurrency(
            value.value ?? 0,
            value.data?.currency ?? Currency.Aud,
          );
        },
      },
      {
        context: {
          isAdmin: true,
        },
        field: "customer_payment_amount",
        headerName: "Customer payments",
        enableValue: true,
        valueFormatter: (value) => {
          if (value.value === undefined) {
            return "";
          }

          return formatCurrency(
            value.value ?? 0,
            value.data?.currency ?? Currency.Aud,
          );
        },
      },
      {
        headerName: "Supplier reference",
        field: "supplier_reference",
        initialHide: true,
      },
      {
        headerName: "Origin",
        field: "relocation.departure_city_id",
        valueFormatter: (p) => cities.find((c) => c.id === p.value)?.name ?? "",
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params: any) => {
            return cities.find((c) => c.id === params.value)?.name ?? "";
          },
        },
      },
      {
        headerName: "Destination",
        field: "relocation.delivery_city_id",
        valueFormatter: (p) =>
          cities.find((c) => c.id === p.value)?.name ?? "--",
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params: any) => {
            return cities.find((c) => c.id === params.value)?.name ?? "--";
          },
        },
      },
      {
        headerName: "Vehicle",
        field: "relocation.vehicle.name",
        filter: "agSetColumnFilter",
      },
      {
        field: "depart_at",
        initialHide: true,
        valueGetter: (value) => {
          return dayjs(value.data?.depart_at).toDate();
        },
        filter: "agDateColumnFilter",
        filterParams: {
          // pass in additional parameters to the Date Filter
        },
      },
      {
        field: "deliver_at",
        initialHide: true,
      },
      {
        headerName: "Relocation",
        field: "relocation.reference",
        initialHide: true,
      },
      {
        field: "name",
        initialHide: true,
      },
      {
        field: "email",
        initialHide: true,
      },
      {
        field: "phone",
        initialHide: true,
      },
      {
        field: "date_of_birth",
        initialHide: true,
      },
    ];

    return allColumns.filter((col) => {
      if (isAdmin) {
        return true;
      }

      return !col.context?.isAdmin;
    });
  }, [isAdmin]);

  const rowSelection = useMemo<
    RowSelectionOptions | "single" | "multiple"
  >(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  return (
    <AgGridReact<ReportBookingListItem>
      className="mt-3 flex-grow"
      ref={ref}
      rowData={data}
      rowSelection={rowSelection}
      theme={imoovaTheme}
      columnDefs={columns}
      defaultColDef={defaultColDef}
      enableCharts={true}
      cellSelection={true}
      sideBar={sidebar}
    />
  );
}
