import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { RelocationStatus } from "@/gql/graphql";
import { ResultOf } from "@graphql-typed-document-node/core";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";

export function RelocationWarningBanner({
  relocation,
}: {
  relocation: ResultOf<typeof relocationRecordQuery>["relocation"];
}) {
  const departureDates = relocation.departure_dates;

  if (
    relocation.status === RelocationStatus.Archived ||
    departureDates.length > 0
  ) {
    return null;
  }

  return (
    <WarningBanner>
      <p>
        This relocation has <b>no available departure dates</b>, please review
        the relocation and change one of the following:
      </p>
      <ul className="list-inside list-disc">
        <li>The minimum number days allowed</li>
        <li>Extend the available to date</li>
        <li>
          Adjust the office hours to open the depot on either the pickup or
          drop-off date
        </li>
      </ul>
      <p className="mt-2">
        Should you have any issues please send us an email:{" "}
        <a className="font-bold underline" href="mailto:admin@imoova.com">
          admin@imoova.com
        </a>
      </p>
    </WarningBanner>
  );
}
