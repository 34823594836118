import { graphql } from "@/gql";

export const recentBookings = graphql(`
  query RecentBookings(
    $first: Int!
    $page: Int!
    $status: [BookingStatus!]
    $supplierId: ID
    $orderBy: [QueryBookingsOrderByRelationOrderByClause!]
    $where: QueryBookingsWhereWhereConditions
  ) {
    bookings(
      first: $first
      page: $page
      status: $status
      supplier_id: $supplierId
      orderBy: $orderBy
      where: $where
    ) {
      data {
        id
        reference
        name
      }
    }
  }
`);
