import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";

export function ReportPulseScreen() {
  const { getToken } = useAuth();

  const { data } = useQuery({
    queryKey: ["AuthToken"],
    queryFn: () =>
      getToken({
        template: "admin",
      }),
  });

  return (
    <div className="overflow-hidden rounded-md shadow-sm">
      {data ? (
        <iframe
          className="h-[800px] w-full"
          src={new URL(
            `/pulse?token=${data}`,
            import.meta.env.VITE_API_URL,
          ).toString()}
        />
      ) : null}
    </div>
  );
}
