import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import {
  VehicleListItem,
  vehicleListQuery,
} from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { Link } from "@/components/catalyst/link";
import {
  VehicleRecord,
  vehicleRecordQuery,
} from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { VehicleCreateDialog } from "@/app/Vehicles/Components/VehicleCreateDialog";
import { ComponentPropsWithoutRef } from "react";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { CreateVehicleInput } from "@/gql/graphql";

export function VehicleInput({
  initialFixtureValues,
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof vehicleListQuery>,
  VariablesOf<typeof vehicleListQuery>,
  VehicleListItem,
  ResultOf<typeof vehicleRecordQuery>
> & {
  initialFixtureValues?: Partial<CreateVehicleInput>;
}) {
  const { supplierId } = useGlobalSupplier();
  const { openAsPromise } = useDialog<
    ComponentPropsWithoutRef<typeof VehicleCreateDialog>,
    VehicleRecord
  >(VehicleCreateDialog);

  return (
    <ComboboxInput
      document={vehicleListQuery}
      accessor={(data) => data.vehicles.data}
      getQueryVariables={(search) => ({
        first: 20,
        page: 1,
        search,
      })}
      recordDocument={vehicleRecordQuery}
      recordAccessor={(data) => data.vehicle}
      getViewNode={(item) =>
        item ? (
          <Link
            className="text-blue-500"
            to={"/vehicles/$id"}
            params={{
              id: item.id,
            }}
          >
            {item.name}
          </Link>
        ) : null
      }
      getKey={(i) => i.id}
      getListItemNode={(i) => i.name}
      getInputNode={(i) => i.name}
      fixture={(search) => {
        return {
          label: `Create '${search}'`,
          onClick: () => {
            return openAsPromise({
              initialValues: {
                supplier: {
                  connect: supplierId ?? null!,
                },
                name: search,
                ...initialFixtureValues,
              },
            });
          },
        };
      }}
      {...props}
    />
  );
}
